import React from 'react'
import Layout from '../../components/de/Layout/Layout'
import WorksPage from '../../components/de/Works/WorksPage'

const Work = () => (
  <Layout
    title='Unsere Werke | the.good.code; - Maßgeschneiderte IT-Dienste für Ihre Bedürfnisse'
    description='Entdecken Sie das Portfolio der IT-Dienstleistungen, die wir bei the.good.code; entwickelt haben. Von kundenspezifischer Software bis hin zur Web-Entwicklung, unsere Arbeiten sind auf die Bedürfnisse unserer Kunden zugeschnitten.'
  >
    <WorksPage />
  </Layout>
)

export default Work
